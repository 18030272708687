import React, { useState, useEffect } from "react";
import "./Loader.css"; // Import your CSS file for styling

const Loader = ({ isLoading }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const images = [
    "./loader/1.png",
    "./loader/2.png",
    "./loader/3.png",
    "./loader/4.png",
    "./loader/5.png",
    "./loader/6.png",
    "./loader/7.png",
    "./loader/8.png",
    "./loader/9.png",
    "./loader/10.png",
    "./loader/11.png",
    "./loader/12.png",
    "./loader/13.png",
    "./loader/14.png",
    "./loader/15.png",
    "./loader/16.png",
    "./loader/17.png",
    "./loader/18.png",
    "./loader/19.png",
    "./loader/20.png",
    "./loader/21.png",
    "./loader/22.png",
    "./loader/23.png",
    "./loader/24.png",
  
  ]; // List of PNG images

  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 100); // Change image every 300ms (adjust as needed)
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isLoading, images.length]);

  return (
    <div className="loader-container">
      {isLoading && (
        // <div class="loader-1"></div>
        <img
          src={images[imageIndex]}
          alt="Loading..."
          className="loader-image"
        />
      )}
    </div>
  );
};

export default Loader;
