import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import getBrowserFingerprint from "get-browser-fingerprint";
import instance from "./Components/common/Api";
import { useState } from "react";

// const [sentFcm, setSentFcm] = useState(true);

const firebaseConfig = {
  // apiKey: "AIzaSyBY-ZrueBktt4Ku6Yrt4lSZmDKub-zBk-o",
  // authDomain: "olaple-demo.firebaseapp.com",
  // projectId: "olaple-demo",
  // storageBucket: "olaple-demo.appspot.com",
  // messagingSenderId: "295885814777",
  // appId: "1:295885814777:web:3f9678400352ec7ece1b28",
  apiKey: "AIzaSyDYprtNxFWWn7QaWLZYjEBBi5RdHAmGWis",
  authDomain: "bebu-ebb9d.firebaseapp.com",
  projectId: "bebu-ebb9d",
  storageBucket: "bebu-ebb9d.appspot.com",
  messagingSenderId: "394161949677",
  appId: "1:394161949677:web:d38dfb2779ef2d8eee35e4",
  measurementId: "G-6XMHQW9B7Q",
};

// console.log("*** Firebase Config ***", firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);
// const messaging = getMessaging(firebaseApp);
export const messaging = getMessaging(firebaseApp);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  // console.warn(permission);
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BBqTp4gYHtWGX757dMN7aLg0rHEOorX4yl-QVHLxy4ayhMYWXelII3_mc7zeeGyz417U2qQ5YDTh29ROyBmsgIM",
    });
    console.log(token);
    localStorage.setItem("NewfcmOlaple", JSON.stringify(token));
    return token;
  }
};

export default firebaseApp;

// export const updateNotification = async (token) => {
//   instance
//     .post(`/saveUnauthUsersDeviceDetails?id=1&FCM-TOKEN=${token}`)
//     .then((response) => {
//       console.warn(response.data);
//     })
//     .catch((error) => {
//       console.error("Error fetching data:", error);
//     })
//     .finally(() => {});
// };
