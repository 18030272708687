import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./UserContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
//OlapleTemp: 393189065216-ij9kinno2rdani8iarm1vp83akhirr8p.apps.googleusercontent.com
//14688835477-u5ijskgkc42m4gaf1aa5msgi2u3kkpva.apps.googleusercontent.com (demo)
//k_wmBgzJlWoJ8mFjLF8bQmTy
root.render(
  <GoogleOAuthProvider clientId="103920994093-ili1acopucngrmhrmtgq092stlnumphm.apps.googleusercontent.com">
    <React.StrictMode>
      <UserProvider>
        <App />
      </UserProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

//register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
