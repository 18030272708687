import React, { useEffect, useState } from "react";
import "./Login.css"; // You can define your custom styles for the Login component
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { FaFacebook, FaGoogle, FaEye, FaEyeSlash } from "react-icons/fa";
import CustomInput from "../FooterPages/CustomInput";
import { useUser } from "../../UserContext";
import instance, { APP_COLORS, APP_IMAGES } from "../common/Api";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { Toast } from "react-bootstrap";
import {
  FaKeycdn,
  FaLock,
  FaMobile,
  FaUser,
  FaUserAstronaut,
} from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
import ReactPixel from "react-facebook-pixel";

const LoginForm = (props) => {
  const navigate = useNavigate();
  const [showOTPView, setShowOTPView] = useState(false);
  const [newOTP, setNewOTP] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [regMobile, setRegMobile] = useState("");
  const [regPassword, setRegPassword] = useState("");
  const [regfullName, setRegfullName] = useState("");
  const [fcmTOKEN, setFcmTOKEN] = useState("");
  const [username, setUsername] = useState("");

  const { setUserData, updateUser } = useUser();
  const [userSocial, setUserSocial] = useState([]);
  const [profileSocial, setProfileSocial] = useState([]);

  function isMobileDevice() {
    return window.matchMedia("(max-width: 1000px)").matches;
  }
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [loading, setLoading] = useState(false);
  let timeout;

  useEffect(() => {
    if (localStorage.getItem("NewfcmOlaple") !== null) {
      const storedUserFcmToken = localStorage
        .getItem("NewfcmOlaple")
        .replace(/"/g, "");
      setFcmTOKEN(storedUserFcmToken);
    }

    if (userSocial) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userSocial.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${userSocial.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          // console.warn(res.data);
          // console.warn(res.data.name);
          // console.warn(res.data.picture);
          // console.warn(res.data.email);
          //
          handleUpdateGoogleUser(
            res.data.name,
            res.data.email,
            res.data.picture
          );
          // setProfileSocial(res.data);
          // localStorage.setItem("OlapleAppUser", JSON.stringify(res.data));
          // localStorage.setItem("SocialLogin", true);
          // window.location.href = "/";
        })
        .catch((err) => console.log(err));
    }

    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [userSocial]);

  const loginGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setUserSocial(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const logOutGoogle = () => {
    googleLogout();
    setProfileSocial(null);
  };

  const [errorMobile, setErorMobile] = useState("");

  const handleUpdateGoogleUser = (name, email, picture) => {
    setLoading(true);
    const login_With = isPWA() ? "pwa" : "web";

    instance
      .post(
        `/registerManual?device_type=web&authServiceProvider=GOOGLE&fullName=${name}&email=${email}&platform=WEB&login_by=google&picture=${picture}&device_token=${fcmTOKEN}&login_with=${login_With}`
      )
      .then((response) => {
        // setUserData(null);
        const data = response.data;
        //console.log("Response:registerManual", response.data);
        // updateUser(data);
        localStorage.setItem("OlapleAppUser", JSON.stringify(data));
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        timeout = setTimeout(() => {
          setLoading(false);
        }, 5000);
      });
    timeout = setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  const ValidateUserMobile1 = async () => {
     ReactPixel.track("SignUp_Initiated", {
       label: "SignUp",
     });
    
    if (regMobile.length === 10 && regPassword !== "" && regfullName !== "") {
      // setNewFullName(regfullName);
      setMobile(regMobile);
      setPassword(regPassword);
      const payload = {
        userD: {
          authServiceProvider: null,
          browserVersion: "Chrome 124",
          consumerSubscription: null,
          contact: regMobile,
          createdAt: null,
          device_token: "my_device_token",
          device_type: "web",
          email: null,
          enabled: null,
          fullName: regfullName,
          id: null,
          ip: props.ip,
          login_by: "manual",
          login_token: null,
          password: regPassword,
          platform: "WEB",
          primaryRole: null,
          profileImageId: null,
          refferer: null,
          state: null,
          token: null,
          userID: null,
          username: null,
        },
      };
      //console.log("LoginHandle:", regMobile, mobile, regPassword, password);
      instance
        .post(`/otp/sendRegisterOTP?mobileNumber=${regMobile}`, payload)
        .then((response) => {
          //console.log("ValidateUserMobile1", response);
          // setUserData(null);
          const data = response.data;
          if (data.success) {
            //console.log("ValidateUserMobile1", data);
            // ManualRegister3();
            // setNewOTP(data.otptext);
            setShowOTPView(true);
            setErorMobile("");
          }
        })
        .catch((error) => {
          setErorMobile(error.response.data.error_messages);
          alert(error.response.data.error_messages);
        })
        .finally(() => {
          // setLoading(false); // Set loading to false when fetch is complete
        });
    } else {
      if (regfullName === "") {
        alert("Full Name filed cannot be empty");
      } else if (regPassword === "") {
        alert("Password filed cannot be empty");
      } else if (regMobile === "") {
        // alert("Please enter a valid mobile number.");
        setErorMobile("Please enter a valid mobile number.");
      } else if (regMobile.length !== 10) {
        setErorMobile("Please enter a valid mobile number.");
        // alert("Please enter a valid mobile number.");
      }
    }
  };
  // "error_messages": "SQLSTATE[23000]: Integrity constraint violation: 1048 Column 'name' cannot be null (SQL: insert into `sub_profiles` (`user_id`, `name`, `picture`, `status`, `updated_at`, `created_at`) values (5837, ?, https:\/\/adminpanelconnect.olaple.com\/placeholder.png, 1, 2024-04-09 08:57:18, 2024-04-09 08:57:18))",

  const VerifyNewOTPHandle2 = async () => {
    instance
      .post(`/otp/validate?mobileNumber=${regMobile}&otpText=${newOTP}`)
      .then((response) => {
        const data = response;
        console.log(response.data.success, "otpvalidate");

        if (response.data.success) {
          LoginHandle();
          // ManualRegister3();
        } else {
          alert(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        // setLoading(false); // Set loading to false when fetch is complete
      });
    // https://adminpanelconnect.bebu.app/userApi/otp/validate?mobileNumber=5555555555&otpText=599070
  };
  ////`/registerManual?device_type=web&contact=${regMobile}&fullName=${regfullName}&password=${regPassword}&platform=WEB`
  const ManualRegister3 = async () => {
    setMobile(regMobile);
    setPassword(regPassword);
    if (regMobile.length === 10 && regPassword !== "") {
      instance
        .post(
          `/registerManual?device_type=web&contact=${regMobile}&fullName=${regMobile}&password=${regPassword}&platform=WEB&username=${regfullName}&login_by=manual`
        )
        .then((response) => {
          const data = response.data;
          if (data.success) {
            window.location.href = "/login";
            // LoginHandle();
            // LoginHandle();
          }
        })
        .catch((error) => {
          console.error("Error fetching registerManual data:", error);
          alert(error.error_messages);
        })
        .finally(() => {});
    } else {
      alert("Please enter a valid mobile number and password.");
    }
  };

  const ValidateUserIfPresent = async () => {
    if (mobile.length === 10 && password !== "") {
      //console.log("ValidateUserIfPresent:", mobile, password);
      instance
        .post(`/otp/sendRegisterOTP?mobileNumber=${mobile}&ip=${props.ip}`)
        .then((response) => {
          // setUserData(null);
          const data = response.data;
          if (data.success) {
            //console.log("ValidateUserMobile1", data);
            // setNewOTP(data.otptext);
            // setShowOTPView(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          // setLoading(false); // Set loading to false when fetch is complete
        });
    } else {
      alert("Please enter a valid mobile number");
    }
  };

  const LoginUpdate = async () => {
    setLoading(true);

    if (mobile.length === 10 && password !== "") {
      //console.log("LoginHandle:", mobile, password);
      const login_With = isPWA() ? "pwa" : "web";

      instance
        .post(
          `/login?device_token=${fcmTOKEN}&mobile=${mobile}&password=${password}&device_type=web&ip=${props.ip}&login_by=manual&FINGERPRINT=${window.navigator.userAgent}&DEVICE_LANGUAGE=${window.navigator.language}&login_with=${login_With},`
        )
        .then((response) => {
          // setUserData(null);
          if (response.data.success) {
            // const data = response.data[0];
            //console.log("Response:", response.data[0]);
            // updateUser(data);
            // localStorage.setItem("BebuAppUser", JSON.stringify(data));
            window.location.href = "/";
          } else {
            alert(
              `${response.data["error_messages "]},You may have entered wrong mobile or password.`
            );

            // toggleToast();
            // alert(response.data["error_messages "], "Please Try ");
            // ValidateUserIfPresent();
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          timeout = setTimeout(() => {
            setLoading(false);
          }, 3000);
        });
    } else {
      alert("Please enter a valid mobile number and password.");
    }
    timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const LoginHandle = async () => {
    ReactPixel.track("Login_Initiated", {
      label: "Login",
    });

    setLoading(true);
    if (mobile.length === 10 && password !== "") {
      //console.log("LoginHandle:", mobile, password);
      instance
        .post(
          `/profileUser?id=null&token=TokenID&contact=${mobile}&password=${password}&prev_regID=null&reg_Mobile=${mobile}&ip=${props.ip}`
        )
        .then((response) => {
          // setUserData(null);
          if (response.data.success) {
            const data = response.data[0];
            //console.log("Response:", response.data[0]);
            updateUser(data);
            localStorage.setItem("OlapleAppUser", JSON.stringify(data));
            LoginUpdate();
            // window.location.href = "/";
          } else {
            alert(
              `${response.data["error_messages "]},You may have entered wrong mobile or password.`
            );

            // toggleToast();
            // alert(response.data["error_messages "], "Please Try ");
            // ValidateUserIfPresent();
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          timeout = setTimeout(() => {
            setLoading(false);
          }, 3000);
        });
    } else {
      if (regPassword === "") {
        alert("Password filed cannot be empty");
      } else if (regMobile === "") {
        alert("Please enter a valid mobile number.");
      } else if (regMobile.length !== 10) {
        alert("Please enter a valid mobile number.");
      }
    }
    timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const isFormValid = () => {
    return username.trim() !== "" && password.trim() !== "";
  };

  // Function to detect if the app is running as a PWA
  const isPWA = () => {
    return (
      window.matchMedia("(display-mode: standalone)").matches || // Check for standalone mode
      window.navigator.standalone // iOS-specific standalone check
    );
  };

  const handlePassForgot = () => {
    navigate("/forgot-password");
  };
  return (
    <>
      <div
        style={{
          paddingTop: "3rem",
          width: "100%",
          minHeight: "51rem",
          position: "relative",
          backgroundImage: `url(${APP_IMAGES.APP_MAIN_BG_WEB})`,
          backgroundSize: "cover",
          backgroundRepeat: "round",
          backgroundBlendMode: "overlay",
        }}
      >
        <h1
          style={{
            color: APP_COLORS.APP_DARK_1,
            marginLeft: isMobile ? "9%" : "31rem",
          }}
        >
          {" "}
          {isLogin ? "Login" : "Register"}
        </h1>
        <div className="centered-container">
          <Container
            className="custom-container"
            style={{
              backgroundColor: APP_COLORS.APP_NAV_COLOR,
              // backgroundImage: `url(${APP_IMAGES.POP_UP_BG})`
            }}
          >
            <Row></Row>

            <Row className="custom-row">
              {isLogin ? (
                <Col
                  xs={12}
                  md={6}
                  className="custom-col-login border-md-end order-md-1 order-2 no-border mt-3 mt-md-0"
                >
                  <Form>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FaMobile
                        style={{
                          marginRight: "10px",
                          marginLeft: "-22px",
                          color: APP_COLORS.APP_DARK_1,
                          marginBottom: "75px",
                        }}
                      />
                      <div style={{ flexGrow: 1 }}>
                        {" "}
                        <CustomInput
                          label="Mobile Number"
                          // placeholder="Enter your mobile number"
                          error="Please enter a 10-digit mobile number"
                          type="number"
                          onValueChange={(value) => setMobile(value)}
                        />
                        <p
                          className="custom-muted"
                          style={{ color: APP_COLORS.APP_DARK_2 }}
                        >
                          Only Indian Mobile Numbers are allowed, others may use
                          Social Login
                        </p>
                      </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FaLock
                        style={{
                          marginRight: "10px",
                          marginLeft: "-22px",
                          color: APP_COLORS.APP_DARK_1,
                          marginBottom: "25px",
                        }}
                      />
                      <div style={{ flexGrow: 1 }}>
                        {" "}
                        <CustomInput
                          label="Password"
                          // placeholder="Enter your password"
                          error="Password field cannot be empty"
                          type="password"
                          onValueChange={(value) => setPassword(value)}
                        />
                      </div>
                    </div>
                  </Form>

                  <div className="password-input-container"></div>
                  <div className="custom-button-div-login">
                    <Button
                      style={{
                        backgroundColor: "#2c2c2e00",
                        borderWidth: "0",
                        fontSize: "16px",
                        color: APP_COLORS.APP_DARK_2,
                      }}
                      className="w-100 mb-3"
                      onClick={handlePassForgot}
                      // disabled={!isFormValid()}
                    >
                      Forgot Password?
                    </Button>
                  </div>
                  <Row className="mt-3 mt-md-0">
                    <Col xs={12} md={6} className="mb-3 mb-md-0 pr-md-1">
                      <Button
                        style={{
                          backgroundColor: APP_COLORS.APP_PINK,
                          borderWidth: "0",
                          color: APP_COLORS.APP_WHITE,
                        }}
                        className="w-100 text-white"
                        // disabled={}
                        onClick={() => LoginHandle()}
                      >
                        Login
                      </Button>
                    </Col>
                    <Col xs={12} md={6} className="pl-md-1">
                      <Button
                        variant="secondary"
                        className="w-100 text-white"
                        onClick={() => setIsLogin(false)}
                      >
                        Register Now
                      </Button>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col
                  xs={12}
                  md={6}
                  className="custom-col-login border-md-end order-md-1 order-2 no-border mt-3 mt-md-0"
                >
                  <Form>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FaUser
                        style={{
                          marginRight: "10px",
                          marginLeft: "-22px",
                          color: "white",
                          marginBottom: regfullName == "" ? "30px" : "10px",
                        }}
                      />
                      <div style={{ flexGrow: 1 }}>
                        <CustomInput
                          label="Full Name"
                          // placeholder="Enter Full Name"
                          error={
                            regfullName === ""
                              ? "Full name field cannot be empty"
                              : ""
                          }
                          type="text"
                          onValueChange={(value) => setRegfullName(value)}
                        />
                      </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FaMobile
                        style={{
                          marginRight: "10px",
                          marginLeft: "-22px",
                          color: APP_COLORS.APP_DARK_1,
                          marginBottom: "25px",
                        }}
                      />
                      <div style={{ flexGrow: 1 }}>
                        {" "}
                        <CustomInput
                          label="Mobile Number"
                          // placeholder="Enter your mobile number"
                          error={errorMobile}
                          type="number"
                          onValueChange={(value) => setRegMobile(value)}
                        />
                      </div>
                    </div>
                    <p
                      className="custom-muted"
                      style={{
                        fontSize: "10px",
                        marginBottom: "1px",
                        color: APP_COLORS.APP_DARK_2,
                      }}
                    >
                      Only Indian Mobile Numbers are allowed, others may use
                      Social Login
                    </p>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FaLock
                        style={{
                          marginRight: "10px",
                          marginLeft: "-22px",
                          color: APP_COLORS.APP_DARK_1,
                          marginBottom: "25px",
                        }}
                      />
                      <div style={{ flexGrow: 1 }}>
                        <CustomInput
                          label="Password"
                          // placeholder="Enter your password"
                          error={
                            regPassword === ""
                              ? "Password field cannot be empty"
                              : ""
                          }
                          type="password"
                          onValueChange={(value) => setRegPassword(value)}
                        />
                      </div>
                    </div>

                    {showOTPView && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FaLock
                          style={{
                            marginRight: "10px",
                            marginLeft: "-22px",
                            color: APP_COLORS.APP_DARK_1,
                            marginBottom: "25px",
                          }}
                        />
                        <div style={{ flexGrow: 1 }}>
                          <CustomInput
                            label="OTP"
                            placeholder="Enter your OTP"
                            error="OTP field cannot be empty"
                            type="text"
                            onValueChange={(value) => setNewOTP(value)}
                          />
                        </div>
                      </div>
                    )}
                  </Form>
                  <Row className="mt-3 mt-md-0">
                    <Col xs={12} md={6} className="mb-3 mb-md-0 pr-md-1">
                      <Button
                        style={{
                          backgroundColor: APP_COLORS.APP_PINK,
                          borderWidth: "0",
                        }}
                        className="w-100 text-white"
                        // disabled={}

                        onClick={() =>
                          showOTPView
                            ? VerifyNewOTPHandle2()
                            : ValidateUserMobile1()
                        }
                      >
                        {showOTPView ? "Verify" : "Register"}
                      </Button>
                      {showOTPView && (
                        <Button
                          style={{
                            backgroundColor: "#58b7aa00",
                            borderWidth: "0",
                          }}
                          className="w-100 text-white"
                          // disabled={}
                          onClick={() => ValidateUserMobile1()}
                        >
                          Resend?
                        </Button>
                      )}
                    </Col>
                    <Col xs={12} md={6} className="pl-md-1">
                      <Button
                        style={{
                          backgroundColor: "#2c2c2e",
                          borderWidth: "0",
                          fontSize: "12px",
                          height: "36px",
                        }}
                        className="w-100 mb-3 text-white"
                        // disabled={!isFormValid()}
                        onClick={() => setIsLogin(true)}
                      >
                        Already Have Account
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col xs={12} lg={3} className="order-md-2 order-1">
                <h5 style={{ color: APP_COLORS.APP_DARK_1 }}>Social Login</h5>
                <p
                  className="custom-muted mt-1"
                  style={{ color: APP_COLORS.APP_DARK_2 }}
                >
                  Quickly sign in with your social network
                </p>
                <Button
                  onClick={() => {
                    loginGoogle();
                    ReactPixel.track("SocialLogin_Initiated", {
                      label: "SocialLogin",
                    });
                  }}
                  style={{ backgroundColor: "transparent", border: "none" }}
                >
                  <img
                    src={"./GoogleBtn_bg.png"}
                    alt="Google logo"
                    style={{
                      borderRadius: "5px",

                      height: "3rem",
                      marginLeft: "-10px",
                      marginBottom: "2px",
                    }}
                  />
                </Button>
                {/* <Button variant="primary" className="w-100">
                <FaFacebook className="mr-2 mb-1" /> Facebook
              </Button> */}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {loading && (
        <div className="loading-overlay">
          <Loader isLoading={loading} />
        </div>
      )}
    </>
  );
};

export default LoginForm;
