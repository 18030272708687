import axios from "axios";

export const APP_CONSTANT = {
  APP_NAME: "Ubethu",
  APP_EMAIL: "ubethu.com",
};

export const APP_COLORS = {
  APP_BOTTOM_TEXT: "",
  APP_DARK_1: "#ffffff", //white
  APP_DARK_2: "#ffffff", //white
  APP_PINK: "#F5679D",
  APP_LIGHT_1: "#74A9D0",
  APP_LIGHT_2: "#EFBB4E",
  APP_BLACK: "black",
  APP_WHITE: "#B1B6BE",
  APP_NAV_COLOR: "#9393E1",
  APP_MOB_FOOTER: "#9393E1",
  // "#9a9a9a"

  // Add more colors as needed
};

export const APP_IMAGES = {
  HEADER_ICON: "/header_logo.png",
  POP_UP_BG: "./pop-image.jpg",
  APP_BG_IMG: "",
  PLACE_VERTI_IMG: "/placeholder-vert.png",
  PLACE_HORIZ_IMG: "/placeholder-hori.png",
  SUB_BG_IMAGE: "/plans_bg.png",
  APP_MAIN_BG_WEB: "/main-bg-web.jpg",
  APP_MAIN_BG_MOB: "/main-bg-mob.jpg",
};
const productionBaseUrl = "https://adminpanelconnect.rokkt.tv/userApi"; //abethu
const developmentBaseUrl = "https://adminpanelconnect.rokkt.tv/userApi";

const instance = axios.create({
  baseURL: developmentBaseUrl,
});

export default instance;

window.getUserIP = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching user IP:", error);
    return "Unknown";
  }
};
