import React, { useState } from "react";
import "./CustomInput.css";
import { Form, Button, InputGroup } from "react-bootstrap";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const CustomInput = ({
  label,
  placeholder,
  error,
  type,
  as,
  onValueChange,
  required,
  validator,
  disable,
  maxLength,
}) => {
  const [value, setValue] = useState("");
  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
    if (required && !value.trim()) {
      setValidationError(`${label} is required`);
    } else if (validator && !validator(value)) {
      setValidationError(`Invalid ${label}`);
    } else {
      setValidationError(null);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onValueChange) {
      onValueChange(e.target.value); // Pass the value to the parent component
    }
  };

  return (
    <div className="custom-input-container">
      <Form.Group controlId={`formCustomInput-${label}`}>
        <Form.Control
          maxLength={maxLength}
          disabled={disable}
          type={type}
          as={as}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={
            (focused || value.trim()) && !validationError
              ? "custom-input-field focused"
              : "custom-input-field"
          }
        />
        <Form.Label className="custom-input-label">{label}</Form.Label>
        {error && <Form.Text className="custom-input-error">{error}</Form.Text>}
        {validationError && (
          <Form.Text className="custom-input-error">
            {validationError}
          </Form.Text>
        )}
      </Form.Group>
    </div>
  );
};

export default CustomInput;
